
import { store } from '@/store/store';
import { defineComponent } from 'vue';
import { TWindowSize } from '@/store/state';
import { validateEmail } from '@/utils';
import { MAX_CHAR_CONTACTUS_MESSAGE, STATE} from '@/constants';
import { ACTION } from '@/store/actions';
import CSS from 'csstype';

export default defineComponent({
    name: 'ContactUsContent',
    components: { },
    data() {
        return {
            name: '',
            email: '',
            message: '',

            nameError: false,
            emailError: false,
            messageError: false,
            maxMessageLength: MAX_CHAR_CONTACTUS_MESSAGE
        };
    },
    computed: {
        windowSize(): TWindowSize {
            return store.state.windowSize
        },
        viewportHeight(): CSS.Properties {
            return {
                'height': `calc(${this.windowSize.height}px - 4em)`
            } as CSS.Properties;
        },
        validName(): boolean {
            return this.name !== '';
        },
        validEmail(): boolean {
            return validateEmail(this.email);
        },
        validMessage(): boolean {
            return this.message.length < this.maxMessageLength;
        },
        validRequest(): boolean {
            return (
                this.validName
                && this.validEmail
                && this.validMessage
                && this.message !== ''
            );
        },
        hasInputErrorName(): boolean {
            return this.nameError;
        },
        hasInputErrorEmail(): boolean {
            return this.emailError;
        },
        hasInputErrorMessage(): boolean {
            return this.messageError;
        },
        isReady(): boolean {
            return store.state.emailRequestStatus.status === STATE.DONE;
        },
        isSending(): boolean {
            return store.state.emailRequestStatus.status === STATE.LOADING;
        },
        hasFacebook(): boolean {
            return store.getters.hasFacebook;
        },
        facebookLink(): string {
            return store.getters.facebookLink;
        },
        hasInstagram(): boolean {
            return store.getters.hasInstagram;
        },
        instagramLink(): string {
            return store.getters.instagramLink;
        }
    },
    methods: {
        showErrorName() {
            this.nameError = !this.validName;
        },
        showErrorEmail() {
            this.emailError = !this.validEmail;
        },
        showErrorMessage() {
            this.messageError = !this.validMessage;
        },
        sendRequest(){

            if (!this.validRequest) return;
  
            // Read data
            const RequestData = {
                name  : this.name,
                email : this.email,
                message : this.message,
            };

            // Send email
            store.dispatch(ACTION.SEND_EMAIL_REQUEST, RequestData);

            // Reset values
            this.name = '';
            this.email = '';
            this.message = '';

        }
    }
});
